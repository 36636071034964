import COLORS from '~/lib/helpers/color-helper';

interface Props {
  width?: number;
  height?: number;
  fillColor?: string;
}

export default function PlaySVG({ width = 24, height = 25, fillColor = COLORS.copyDark }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.4462 9.54895L7.79771 1.64381C5.61693 0.436085 3 1.8634 3 4.38865V20.0891C3 22.5046 5.61693 24.0417 7.79771 22.7242L20.4462 14.819C22.5179 13.6113 22.5179 10.7567 20.4462 9.54895Z'
        fill={fillColor}
      />
    </svg>
  );
}

