import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';

import styles from '~/styles/components/page-components/hero-carousel.module.scss';
import Button, { ButtonProps } from '../button';
import { images } from 'sdk';
import COLORS from '~/lib/helpers/color-helper';

import ChevronRightSVG from '~/assets/svg/icons/chevron-right';
import ChevronLeftSVG from '~/assets/svg/icons/chevron-left';
import PlaySVG from '~/assets/svg/icons/play';
import PauseSVG from '~/assets/svg/icons/pause';
import Link from 'next/link';
import Links from '~/lib/helpers/link-helper';
import AppStoreButtonSVG from '~/assets/svg/app-store-button';
import GooglePlayButtonSVG from '~/assets/svg/google-play-button';
import StarFull from '~/assets/svg/icons/star-full';

type CarouselBannerType = 'primary' | 'secondary' | 'tertiary';

interface CarouselBanner {
  title: string;
  description?: string;
  image?: any;
  link: string;
  button?: Partial<ButtonProps>;
  backgroundColor?: string;
}

export default function HeroCarousel() {
  const [banners] = useState<{ [key in CarouselBannerType]: CarouselBanner }>({
    primary: {
      title: 'Download the Pango App!',
      description: 'Open a bookshop of your own with our easy-to-use bookselling tools.',
      image: images('website_photos/mosaic/download-banner-v2.png'),
      link: 'https://pangobooks.page.link/app',
      button: {
        text: 'Get the app',
      },
      backgroundColor: '#E9EEFF',
    },
    secondary: {
      title: `Save and curate books with Pango Shelves`,
      description: 'Use Shelves to create wishlists, track the books you want to read, and save your favorite reads.',
      image: images('website_photos/mosaic/1-4-24-top-right.jpg'),
      link: '/blog/introducing-pango-shelves',
      button: {
        text: 'Read blog',
      },
      backgroundColor: '#FFF2D9',
    },
    tertiary: {
      title: 'Find sellers with shop discounts',
      description: 'Save even more with these sellers who offer free shipping and other discounts.',
      image: images('website_photos/mosaic/1-4-24-bottom-right.jpeg'),
      link: 'https://pangobooks.com/seller-search?search=index%3Dusers%26params%255Bq%255D%3D%252A%26params%255Bquery_by%255D%3Dname%252C%2520username%26params%255Bsort_by%255D%3D_text_match%253Adesc%252Cseller_score%253Adesc%252Cbooks_listed%253Adesc%26params%255Bfilter_by%255D%3Dbooks_listed%253A%253E3%2520%2526%2526%2520discounts.type%253Ashipping%2520%2526%2526%2520discounts.type%253A%255Bpercent%252Cvalue%255D%26params%255Bpage%255D%3D0',
      button: {
        text: 'View bookshops',
      },
      backgroundColor: '#BCA5F6',
    },
  });

  const bannerKeys = Object.keys(banners);
  const [activeIndex, setActiveIndex] = useState(0);

  function slideBanner(direction: 'left' | 'right') {
    if (direction === 'right') {
      setActiveIndex((prevIndex) => (prevIndex + 1) % bannerKeys.length);
    } else {
      setActiveIndex((prevIndex) => {
        return (prevIndex - 1 + bannerKeys.length) % bannerKeys.length;
      });
    }
  }

  // Functionality for carousel's auto-slide and pause
  const [isPaused, setIsPaused] = useState(false);
  const carouselTimerRef = useRef(null);

  const startCarouselTimer = () => {
    carouselTimerRef.current = setInterval(() => slideBanner('right'), 10000);
  };

  const stopCarouselTimer = () => {
    clearInterval(carouselTimerRef.current);
  };

  const togglePause = () => {
    setIsPaused((prevPaused) => !prevPaused);
  };

  useEffect(() => {
    if (!isPaused) {
      startCarouselTimer();
    } else {
      stopCarouselTimer();
    }
    return () => {
      stopCarouselTimer();
    };
  }, [activeIndex, isPaused]);

  function renderCarouselBanner(banner: CarouselBanner, key) {
    return (
      <div key={key} className={styles['carousel-banner']}>
        <div className={styles['carousel-banner-copy']} style={{ backgroundColor: banner.backgroundColor }}>
          <h2>{banner.title}</h2>
          {banner.description && <p>{banner.description}</p>}
          <div>
            {key === 'primary' ? (
              <div className={styles['carousel-banner--primary']}>
                <Link
                  id='os-apple-download'
                  href={Links.appStore.apple}
                  passHref
                  className={styles.appleDownload}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <AppStoreButtonSVG />
                </Link>
                <Link
                  id='os-google-download'
                  href={Links.appStore.google}
                  passHref
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <GooglePlayButtonSVG />
                </Link>

                <div className={styles['ratings']}>
                  <div>
                    {[...Array(5)].map((_, index) => (
                      <StarFull size={2} fillColor={COLORS.yellow} key={index} />
                    ))}
                  </div>
                  <span>12k+ ratings</span>
                </div>
              </div>
            ) : (
              banner.button && (
                <Button
                  {...banner.button}
                  style='tertiary'
                  link={{ href: banner.link }}
                  text={banner.button.text}
                  size='small'
                  aria-label={`Click to ${banner.button.text}`}
                />
              )
            )}
          </div>
        </div>
        <div className={styles['carousel-banner-image']}>
          <Image
            priority
            src={banner.image.marketing.default}
            alt={banner.title}
            quality={80}
            placeholder={'blur'}
            blurDataURL={banner.image.marketing.blur}
            fill
            style={{ objectFit: 'cover', objectPosition: '50%' }}
            unoptimized
          />
        </div>
      </div>
    );
  }

  return (
    <section className={styles.carousel}>
      <div className={styles['carousel-container']}>
        <div className={styles['carousel-banner-wrapper']} style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
          {bannerKeys.map((key) => renderCarouselBanner(banners[key], key))}
        </div>
        <div className={styles['carousel-controls']}>
          <button aria-label='Previous hero banner' onClick={() => slideBanner('left')}>
            <ChevronLeftSVG />
          </button>
          <button aria-label='Next hero banner' onClick={() => slideBanner('right')}>
            <ChevronRightSVG />
          </button>
        </div>
        <div className={styles['carousel-pagination']}>
          {bannerKeys.map((_, index) => (
            <button
              key={index}
              aria-label={`Go to banner ${index + 1}`}
              className={`${styles['carousel-pagination-indicator']} ${index === activeIndex ? styles.active : ''}`}
              onClick={() => setActiveIndex(index)}
            ></button>
          ))}
          <button
            onClick={togglePause}
            className={styles['carousel-pagination-pause']}
            aria-label={isPaused ? 'Play carousel' : 'Pause carousel'}
          >
            {isPaused ? <PlaySVG width={15} height={15} /> : <PauseSVG width={15} height={15} />}
          </button>
        </div>
      </div>
    </section>
  );
}
