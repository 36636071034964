import type { InferGetServerSidePropsType, NextPage } from 'next';

import { getPageData } from '../clients/firebase/admin';

import PageComponents from '../components/page-components';
import PageMetaData from '../components/page-meta-data';

import styles from '../styles/pages/index.module.scss';
import HeyPangoCallout from '../components/hey-pango/hey-pango-callout';
import HeroCarousel from '~/components/page-components/hero-carousel';

const Home: NextPage<InferGetServerSidePropsType<typeof getStaticProps>> = (
  props
) => {
  return (
    <>
      <PageMetaData
        title={props.page.meta.title}
        description={props.page.meta.description}
        twitterCard='app'
      />
      <section role='main' className={styles.container}>
        <HeroCarousel />
        <HeyPangoCallout />
        <PageComponents components={props.page.components} />
      </section>
    </>
  );
};

export async function getStaticProps() {
  const { page, components } = await getPageData('home');

  return {
    props: {
      page: {
        ...page,
        components,
      },
    },
    revalidate: 180,
  };
}

export default Home;
