import COLORS from '~/lib/helpers/color-helper';

interface Props {
  width?: number;
  height?: number;
  fillColor?: string;
}

export default function PauseSVG({ width = 24, height = 25, fillColor = COLORS.copyDark }: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.37147 22.208H5.62288C4.16572 22.208 3 21.008 3 19.508V4.90801C3 3.40801 4.16572 2.20801 5.62288 2.20801H7.37147C8.82862 2.20801 9.99434 3.40801 9.99434 4.90801V19.408C10.0915 21.008 8.92576 22.208 7.37147 22.208Z'
        fill={fillColor}
      />
      <path
        d='M18.375 22.208H16.625C15.1667 22.208 14 21.008 14 19.508V4.90801C14 3.40801 15.1667 2.20801 16.625 2.20801H18.375C19.8333 2.20801 21 3.40801 21 4.90801V19.408C21 21.008 19.8333 22.208 18.375 22.208Z'
        fill={fillColor}
      />
    </svg>
  );
}
