import Callout from '@/components/callout';
import PangoAvatar from '@/components/pango-avatar';
import Links from '@/lib/helpers/link-helper';
import styles from '@/styles/components/hey-pango/hey-pango-callout.module.scss';
import AiIcon from '@/assets/svg/icons/ai';

interface Props {
  title?: string;
  message?: string;
}
export default function HeyPangoCallout(props: Props) {

  return (
    <Callout
      title={props.title || 'Pango AI'}
      message={props.message || 'Your personal Pangolin book shopper is here to help you find the perfect book!'}
      button={{
        text: 'Chat with Pango',
        link: { href: Links.heyPango.index.pathname }
      }}
      icon={
        <PangoAvatar
          customSize={80}
        />
      }
      iconStyleOverrides={styles['icon']}
      containerStyles={styles['container']}
      buttonIcon={<AiIcon strokeColor='white' />}
    />
  );
}